import React, { FC } from 'react';
import styled from 'styled-components';

import BannerOne from '../BannerOne';
import { smMobile, tablet } from 'styles/breakpoints';
import PaymentSteps from 'pages/start/payments/components/PaymentSteps';
import Button from '../Button';
import CancelButton from '../CancelButton';
import { DynamicImage } from 'components';
import { useSelector } from 'react-redux';
import { AppState } from 'state/types';

interface ExpressShippingProps {}

const Container = styled.div``;

const PaymentStepsContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 1.5rem;
  width: 100%;
  background-color: #f6f4ef;
  @media ${tablet} {
    padding: 1.5rem 2.125rem;
  }
  @media ${smMobile} {
    padding: 1.5rem 0.75rem;
  }
`;

const PaymentStepsStyled = styled(PaymentSteps)`
  max-width: 56.25rem;
  width: 100%;
`;
const CancelButtonStyled = styled(CancelButton)`
  margin: 0.75rem 0 3rem 0;
`;

const BookTextContainer = styled.div`
  position: absolute;
  top: 16px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  padding: 0 5px;
  text-transform: uppercase;
`;

const BookContainer = styled.div`
  position: relative;
  width: 138px;
  height: 180px;
  border-radius: 0.29269rem;
`;

const BookText = styled.p<{ textColor: string; $isSmallerText?: boolean }>`
  max-width: 138px;
  word-wrap: break-word;
  font-size: 7px;
  text-align: center;
  font-weight: 800;
  color: ${({ textColor }) => textColor};
  font-size: ${({ $isSmallerText }) => ($isSmallerText ? '4px' : '7px')};
`;

const ItemIcon = styled.img`
  height: 180px;
  border-radius: 4px;
  position: relative;
  z-index: 2;
`;

const BookUpperWraper = styled.div`
  background-color: #f6f4ef;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 2.84rem;
  @media ${tablet} {
    padding-bottom: 1.57rem;
  }
`;

const BookBackground = styled(DynamicImage)`
  position: absolute;
  top: 0;
  right: -20px;
  height: 180px;
  z-index: 1;
`;

const BotTitleStyled = styled.p`
  max-width: 35rem;
  margin-bottom: 0.75rem;
  margin-top: 7rem;

  color: #363648;
  text-align: center;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 2.275rem */
  letter-spacing: -0.028rem;
  @media ${tablet} {
    margin-top: 2rem;
    max-width: 21.4375rem;
    font-size: 1.25rem;
  }
`;

const BotLabelStyled = styled.p`
  max-width: 35rem;
  margin-bottom: 2rem;
  color: #28293d;
  text-align: center;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 1.625rem */
  @media ${tablet} {
    font-size: 1rem;
    max-width: 21.4375rem;
  }
`;

const Title3 = styled(BotTitleStyled)`
  margin-top: 2rem;
`;

const Label3 = styled(BotLabelStyled)`
  @media ${tablet} {
    margin-bottom: 0.75rem;
  }
`;

const TopTextContainer = styled.div`
  background-color: #f6f4ef;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Headline = styled.p`
  color: #1c1c28;
  text-align: center;
  font-family: Open Sans;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 2.8rem */
  margin-bottom: 1rem;
  max-width: 48rem;
  @media ${tablet} {
    max-width: 21.4375rem;
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
  }
`;

const Label = styled.p`
  color: #28293d;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 1.625rem */
  margin-bottom: 2.25rem;
  text-align: center;
  max-width: 48rem;
  @media ${tablet} {
    max-width: 21.4375rem;
    font-size: 1rem;
    margin-bottom: 1rem;
  }
`;

const ExpressShipping: FC<ExpressShippingProps> = ({
  bannerOne,
  paymentSteps,
  headline,
  label,
  PlanSectionComponent,
  planSection,
  headline2,
  label2,
  imageSection,
  headline3,
  label3,
  button,
  cancelButton,
  userHasBoughtPaperbackBook,
  loading,
  handleUpgradeClick,
  localisedProduct,
  handleCancelClick,
  bannerTexts,
}) => {
  const quizAnswers = useSelector((state: AppState) => state.user.quiz_answers);
  const minimizeBookTitle =
    (quizAnswers?.userName?.length ||
      0 + quizAnswers?.userDogName?.length ||
      0) > 15;

  const BookImage = () => (
    <BookContainer>
      <BookBackground alt="" src="upsell/1/ebook-background.png" />
      <ItemIcon alt="" src={quizAnswers.coverUrl} />
      <BookTextContainer>
        <BookText
          textColor={quizAnswers.textColor}
          $isSmallerText={minimizeBookTitle}
        >
          {`${quizAnswers?.userName} & ${quizAnswers?.userDogName}`}
        </BookText>
      </BookTextContainer>
    </BookContainer>
  );

  return (
    <>
      {!bannerTexts && (
        <BannerOne
          {...bannerOne}
          boughtPaperback={userHasBoughtPaperbackBook}
        />
      )}
      <PaymentStepsContainer>
        <PaymentStepsStyled
          {...paymentSteps}
          hasOptionalStep={userHasBoughtPaperbackBook}
        />
      </PaymentStepsContainer>
      <TopTextContainer>
        <Headline>{headline}</Headline>
        <Label>{label}</Label>
      </TopTextContainer>
      <BookUpperWraper>
        <BookImage />
      </BookUpperWraper>
      <PlanSectionComponent {...planSection} />
      <BotTitleStyled>{headline2}</BotTitleStyled>
      <BotLabelStyled>{label2}</BotLabelStyled>
      <BookImage />
      <Title3>{headline3}</Title3>
      <Label3>{label3}</Label3>
      <Button
        bgColor="secondary"
        disabled={loading}
        onClick={handleUpgradeClick}
        {...button}
        secondaryText={`${button.secondaryText} ${localisedProduct?.currency}${localisedProduct?.finalPrice}`}
      >
        {button.text}
      </Button>
      <CancelButtonStyled
        disabled={loading}
        onCancelClick={handleCancelClick}
        noButtonTitle={cancelButton.noButtonTitle}
      />
    </>
  );
};

export default ExpressShipping;
